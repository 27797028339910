<template>
  <v-card :loading="!groups">
    <v-card-title>
      {{ $t("groups") }}
    </v-card-title>
    <v-card-text v-if="groups">
      <v-row>
        <v-spacer />
        <MassDeletion
          type-of-deletion="groups"
          v-on:done="onMassDeleteDone"
          :items="selectedGroups"
          class="mr-2 mb-2"
          v-bind:class="{ xsBlockFix: $vuetify.breakpoint.xs }"
        />
        <CreateGroupBtn
          v-on:click="dialogNewGroup = true"
          class="mr-2 mb-2"
          data-testid="newGroupGroupList"
          :block="$vuetify.breakpoint.xs"
        />
        <UpdateBtn
          v-on:click="update"
          class="mr-2 mb-2"
          data-testid="updateGroupList"
          :block="$vuetify.breakpoint.xs"
        />
      </v-row>
      <br /><br />
      <v-text-field
        :label="$t('search')"
        v-model="search"
        data-testid="searchGroupList"
      />
      <DataTable
        :items="groups"
        :headers="headers"
        :search="search"
        :custom-filter="filterList"
        :show-select="showRowSelect"
        item-key="Id"
        v-model="selectedGroups"
      >
        <template v-slot:item.actions="{ item }">
          <div class="text-end">
            <v-btn outlined @click="select(item)" data-testid="groupSelect">
              <v-icon left small>
                mdi-eye
              </v-icon>
              {{ $t("vis") }}
            </v-btn>
          </div>
        </template>
      </DataTable>
    </v-card-text>
    <v-dialog v-model="dialogNewGroup" :fullscreen="$vuetify.breakpoint.xs">
      <CreateGroup
        v-on:done="handleNewGroupAdd"
        v-on:cancel="dialogNewGroup = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import CreateGroup from "./CreateGroup";
import store from "../../store";
import CreateGroupBtn from "@/components/groups/CreateGroupBtn";
import UpdateBtn from "@/components/misc/UpdateBtn";
import search from "@/util/search";
import config from "@/config/config";
import DataTable from "@/components/dataTable/DataTable.vue";
import MassDeletion from "@/components/massDeletion/MassDeletion.vue";

export default {
  name: "GroupList",
  components: {
    MassDeletion,
    DataTable,
    UpdateBtn,
    CreateGroupBtn,
    CreateGroup
  },

  computed: {
    groups() {
      return store.state.groups;
    },
    headers() {
      let headers = [{ text: this.$t("name"), value: "Navn" }];
      const breakpoint = this.$vuetify.breakpoint;
      if (store.getters.getShowDepartmentInfoInLists && !breakpoint.lg) {
        headers.push({
          text: this.$t("department"),
          value: "AvdelingNavn",
          cellClass: "font-weight-light",
          sortable: false
        });
      }
      if (store.getters.getUserHasSmsCodeword && !breakpoint.lg) {
        headers.push({
          text: this.$t("shortname"),
          value: "KodeNavn",
          cellClass: "font-weight-light"
        });
      }
      headers.push({ text: "", value: "actions" });
      return headers;
    },
    showRowSelect() {
      // Don't bother showing row select for mass deletion if user/customer are using AD provisioning.
      // They can't delete AD contacts. Yes, they may have non-AD contacts, but
      // they are probably few or none of them.
      return !store.state.userProfile?.Bruker.Tilgang.ADProvisjonering;
    }
  },

  data: () => ({
    search: "",
    loading: true,
    dialogNewGroup: false,
    selectedGroups: []
  }),

  created() {
    this.update();
  },

  methods: {
    onMassDeleteDone() {
      this.update();
      this.selectedGroups = [];
      // Clear any selected group since it now may be deleted
      this.emitGroupSelected(null);
    },
    filterList(value, query) {
      return search.whitespaceAsWildcard(value, query);
    },
    update() {
      return store.dispatch("updateGroups");
    },
    select(group) {
      this.emitGroupSelected(group);
      // Go to the top since details card is always at the top
      window.scrollTo(0, 0);
    },
    handleNewGroupAdd(groupData) {
      const group = groupData.group;
      this.dialogNewGroup = false;
      if (group) {
        this.select(group);
      }
      this.update();
    },
    emitGroupSelected(group) {
      this.$emit("groupselected", group);
    }
  }
};
</script>

<style scoped></style>
