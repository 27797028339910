<template>
  <v-container fluid>
    <v-card>
      <v-card-title>{{ $t("contactAndGrpMng") }}</v-card-title>
      <v-card-text>
        <v-tabs>
          <v-tab
            :key="1"
            @click="updateContacts"
            data-testid="contactsContactsAndGroupManagement"
          >
            {{ $t("contacts") }}
          </v-tab>
          <v-tab
            :key="2"
            @click="updateGroups"
            data-testid="groupsContactsAndGroupManagement"
          >
            {{ $t("groups") }}
          </v-tab>
          <v-tab-item :key="1">
            <ContactList
              :update-trigger="contactsUpdateTrigger"
              :codeword-group="!!group?.KodeNavn"
            />
          </v-tab-item>
          <v-tab-item :key="2">
            <v-container grid-list-md fluid>
              <v-layout row wrap>
                <v-flex md12 lg4>
                  <GroupList v-on:groupselected="setGroup" />
                </v-flex>
                <v-flex md12 lg8>
                  <v-card>
                    <!-- See also alternative dialog for smaller sceens futher down -->
                    <GroupDetails
                      v-if="group && !$vuetify.breakpoint.mdAndDown"
                      :group-id="group.Id"
                      v-on:delete="updateGroups"
                    />
                  </v-card>
                  <v-dialog
                    v-model="dialog"
                    v-if="$vuetify.breakpoint.mdAndDown && group"
                    scrollable
                    fullscreen
                  >
                    <GroupDetails
                      v-if="group"
                      :group-id="group.Id"
                      :close-btn="true"
                      v-on:delete="updateGroups"
                      v-on:close="dialog = false"
                    />
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ContactList from "../components/contacts/ContactList";
import GroupList from "../components/groups/GroupList";
import GroupDetails from "../components/groups/GroupDetails";
import store from "../store";

export default {
  name: "ContactAndGroupManagement",
  components: { GroupDetails, GroupList, ContactList },

  data: () => ({
    group: null,
    dialog: true,
    contactsUpdateTrigger: 0
  }),

  methods: {
    setGroup(group) {
      this.group = group;
      this.dialog = true;
    },
    updateContacts() {
      this.contactsUpdateTrigger = Date.now();
    },
    updateGroups() {
      this.group = null;
      store.dispatch("updateGroups");
    }
  }
};
</script>
